import React from 'react'
import { HashRouter, Route, Redirect, Switch } from "react-router-dom";
import router from './router'
import { State } from '../page/layout/state'
import { StateDevice } from '../page/layout/state_device'
import Login from "../page/login/Login"
let authenticate = () => {
    const token = localStorage.getItem("tokenis");
    return token
}
function RouteMap() {
    return (
        <State>
            <StateDevice>
                <HashRouter>
                    <Switch>
                        {router.map((item, index) => {
                            return <Route key={index} path={item.path} render={props =>
                                (!item.auth ? 
                                    (<item.component {...props}>{
                                        <Route key={item.path} path={item.path} exact component={item.component} />
                                        // item.children && item.children.map(c => (
                                            // <Route key={c.path} path={c.path} exact component={c.component} />
                                        // ))
                                    }</item.component>) 
                                : 
                                (
                                    authenticate() ? 
                                    <item.component {...props} >{
                                        item.children && item.children.map(c => (
                                            <Route key={c.path} path={c.path} exact component={c.component} />
                                        ))
                                    }</item.component> 
                                    :
                                    <Redirect to={{
                                        pathname: '/login',
                                        state: { from: props.location }
                                    }} />
                                )
                            )} >
                            </Route>
                        })}
                    </Switch>
                </HashRouter>
            </StateDevice>
        </State>
    )
}



export default RouteMap