import {Modal,Space,Input} from 'antd'
import  {React,useEffect,useState} from "react"
const Sign=(props)=> {
    const [showDialog,SetShowDialog] = useState(true)
    const [mySign,setMySign] = useState('')
    useEffect(()=>{
        SetShowDialog(props.val)
    },[props])

    return(
        <Modal maskClosable={false} title="电子签名"
                visible={showDialog}
                destroyOnClose
                closable={false} cancelText='取消'
                okText='确定'
                onOk={() => {
                    props.getMySgin(mySign)
                    SetShowDialog(false)
                    setMySign('')
                }}
                onCancel={()=>{
                    props.getMySgin('')
                    setMySign('')
                    SetShowDialog(false)
                }}
                >
                <Space direction="vertical">
                    <Input addonBefore="输入签名" placeholder="input" value={mySign}
                        onChange={(e) => {
                            setMySign(e.target.value)
                        }} />
              
                    {/* <font size="3" color="red">{msg1}</font> */}
                </Space>
            </Modal>
    )
}
export default Sign