import React, { createContext, useReducer, useCallback } from 'react';
export const StateContext = createContext({})
export const MainContext = createContext({})
export const TimeFormatSeconds = function (time) {
    var d = time ? new Date(time) : new Date();
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var hours = d.getHours();
    var min = d.getMinutes();
    var seconds = d.getSeconds();

    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    if (hours < 0) hours = '0' + hours;
    if (min < 10) min = '0' + min;
    if (seconds < 10) seconds = '0' + seconds;

    return (year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + seconds);
}
export const OPERATIONTYPE = ['增', '改', '删']

export const State = props => {
    const reducer = (state, action) => {
        switch (action.type) {
            case 'panes':
                return {
                    ...state,
                    panes: action.panes
                }
            case 'devicestate':
                return {
                    ...state,
                    devicestate: action.devicestate
                }
            case 'operationdetails':
                return {
                    ...state,
                    operationdetails: action.operationdetails
                }
            case 'permissiontype':
                return {
                    ...state,
                    permissiontype: action.permissiontype
                }
            case 'permission':
                return {
                    ...state,
                    permission: action.permission
                }
            case 'operation':
                return {
                    ...state,
                    operation: action.operation
                }
            case 'history':
                return {
                    ...state,
                    history: action.history
                }
            case 'alarminfo':
                return {
                    ...state,
                    alarminfo: action.alarminfo
                }
            case 'alarmlog':
                return {
                    ...state,
                    alarmlog: action.alarmlog
                }
            case 'main': {
                return {
                    ...state,
                    main: action.main
                }
            }
            case 'device':
                return {
                    ...state,
                    device: action.device
                }
            case 'node':
                return {
                    ...state,
                    node: action.node
                }
            case 'nodemap':
                return {
                    ...state,
                    nodemap: action.nodemap
                }
            case 'gateway':
                return {
                    ...state,
                    gateway: action.gateway
                }
            case 'devicetype':
                return {
                    ...state,
                    devicetype: action.devicetype
                }
            case 'addresstype':
                return {
                    ...state,
                    addresstype: action.addresstype
                }
            case 'goodstype':
                return {
                    ...state,
                    goodstype: action.goodstype
                }
            case 'address':
                return {
                    ...state,
                    address: action.address
                }
            case 'goods':
                return {
                    ...state,
                    goods: action.goods
                }
            case 'transport':
                return {
                    ...state,
                    transport: action.transport
                }
            case 'transuser':
                return {
                    ...state,
                    transuser: action.transuser
                }  
            case 'addressuser':
                return {
                    ...state,
                    addressuser: action.addressuser
                } 
            case 'tusertype':
                return {
                    ...state,
                    tusertype: action.tusertype
                } 
            case 'datadictionary':
                return {
                    ...state,
                    datadictionary: action.datadictionary
                }
            case 'district':                
                return {
                    ...state,
                    district: action.district
                }
            case 'project':
                return {
                    ...state,
                    district: action.project
                }
            case 'systemuser':
                var ulist = new Array();
                var ulist1 = new Array();//不含普通用户的树形结构 
                function findParentuser(nop, list, child,mode) {
                    if (list) {
                        const nops = list.filter(x => { return x.parent === nop })
                        if (nops.length === 0) {
                            return
                        } else {
                            const nopUser = list.filter(x => x.id === nop )
                            let nopTitle = '';
                            if(nopUser.length>0){
                                nopTitle = nopUser[0].title
                            }
                            let x = 0;
                            for (var j = 0; j < nops.length; j++) {
                                var u = new Object
                                u.key = nops[j].id
                                u.title = nops[j].name//tree  treeselect展示条目
                                u.titles = nops[j].title
                                u.label = nops[j].name//select选择器展示条目
                                u.text = nops[j].title
                                u.value = nops[j].id
                                u.account = nops[j].account
                                u.name = nops[j].name
                                u.type = nops[j].type
                                u.parent = nops[j].parent
                                u.id = nops[j].id
                                u.smscount = nops[j].smscount
                                u.smssendcount = nops[j].smssendcount
                                u.phonesendcount = nops[j].phonesendcount
                                u.phonecount = nops[j].phonecount
                                u.wechatcount = nops[j].wechatcount
                                u.company = nops[j].company
                                u.custom = nops[j].custom
                                u.children = new Array()
                                u.nodes = new Array()
                                if(u.titles===nopTitle){
                                    child.splice(x,0,u)
                                    x+=1;
                                }else{
                                    child.push(u);
                                }
                                // u.titles===nopTitle?child.unshift(u):
                                // child.push(u);
                                findParentuser(nops[j].id, list, u.children,mode)
                                findParentuser(nops[j].id, list, u.nodes,mode)
                            }
                        }
                    }
                }
                if (state.main.type < 102) {
                    let tempuser = action.systemuser.filter(x=>x.type!==100);
                    findParentuser(0, tempuser, ulist);
                    findParentuser(0, tempuser.filter(x=>x.type!==104), ulist1);
                }else {
                    let userLogin =  action.systemuser.length>0?action.systemuser[0]:false
                    var u = new Object
                    u.key = state.main.id
                    u.title = state.main.name
                    u.titles = state.main.title
                    u.text = state.main.title
                    u.label = state.main.name
                    u.value = state.main.id
                    u.account = state.main.account
                    u.name = state.main.name
                    u.type = state.main.type
                    u.parent = state.main.parent
                    u.id = state.main.id
                    u.smscount = userLogin&&userLogin.smscount
                    u.smssendcount = userLogin&&userLogin.smssendcount
                    u.wechatcount = userLogin&&userLogin.wechatcount
                    u.phonesendcount = userLogin&&userLogin.phonesendcount
                    u.phonecount = userLogin&&userLogin.phonecount
                    u.company = userLogin&&userLogin.company
                    u.custom = userLogin&&userLogin.custom
                    u.children = new Array()
                    u.nodes = new Array()
                    findParentuser(state.main.id, action.systemuser, u.children)
                    findParentuser(state.main.id, action.systemuser, u.nodes)
                    ulist.push(u);
                    var u1 = new Object
                    u1.key = state.main.id
                    u1.title = state.main.name
                    u1.titles = state.main.title
                    u1.text = state.main.title
                    u1.label = state.main.name
                    u1.value = state.main.id
                    u1.account = state.main.account
                    u1.name = state.main.name
                    u1.type = state.main.type
                    u1.parent = state.main.parent
                    u1.id = state.main.id
                    u1.smscount = userLogin&&userLogin.smscount
                    u1.smssendcount = userLogin&&userLogin.smssendcount
                    u1.wechatcount = userLogin&&userLogin.wechatcount
                    u1.company = userLogin&&userLogin.company
                    u1.custom = userLogin&&userLogin.custom
                    u1.phonesendcount = userLogin&&userLogin.phonesendcount
                    u1.phonecount = userLogin&&userLogin.phonecount
                    u1.children = new Array()
                    u1.nodes = new Array()
                    findParentuser(state.main.id, action.systemuser.filter(x=>x.type!==104), u1.children);
                    ulist1.push(u1);
                }
                function clearchildrenisZero(list) {
                    for (var i = 0; i < list.length; i++) {
                        if (list[i].children) {
                            if (list[i].children.length === 0) {
                                list[i].children = null
                            } else {
                                clearchildrenisZero(list[i].children)
                            }
                        }
                    }
                }
                clearchildrenisZero(ulist)
                // console.log(ulist)
                return {
                    ...state,
                    systemuser: { tree: ulist, list: action.systemuser,treeM:ulist1}
                }
            case 'video':
                return {
                    ...state,
                    video: action.video
                }
            case 'user_district':
                return {
                    ...state,
                    user_district: action.user_district
                }
            case 'hmi':
                return {
                    ...state,
                    hmi: action.hmi
                }
            case 'alarmtype':
                return {
                    ...state,
                    alarmtype: action.alarmtype
                }
            case 'alarmsuer':
                return {
                    ...state,
                    alarmsuer: action.alarmsuer
                }
            case 'alarmSound':
                return{
                    ...state,
                    alarmSound:action.alarmSound
                }
            case 'setState':
                return{
                    ...state,
                    setState:action.setState
                }
            case 'super':
            return{
                ...state,
                super:action.super
            }
            case 'selUser_dev':
            return{
                ...state,
                selUser_dev:action.selUser_dev
            }
            case 'resolution':
                return{
                    ...state,
                    resolution:action.resolution
                }
            case 'runningtime':{
                return{
                    ...state,
                    runningtime:action.runningtime
                }
            }
            case 'runningtimeCount':{
                return{
                    ...state,
                    runningtimeCount:action.runningtimeCount
                }
            }
            case 'pwdChange':{
                return{
                    ...state,
                    pwdChange:action.pwdChange
                }
            }
            case 'mainTodo':{
                return{
                    ...state,
                    mainTodo:action.mainTodo
                }
            }
            default:
                return state
        }
    }
    const [state, dispatch] = useReducer(reducer, {
        permissiontype: [],
        alarminfo: [],
        datadictionary: [],
        main: [],
        device: [],
        gateway: [],
        devicetype: [],
        district: [],
        user_district: [],
        nodemap: [],
        systemuser: { tree: [], list: [] },
        video: [],
        hmi: [],
        project: [],
        node: [],
        devicestate: [],
        address: [],
        addresstype: [],
        transport: [],
        goodstype: [],
        goods: [],
        transuser:[],
        goods:[],
        alarmtype: [],
        alarmsuer: [],
        alarmSound:false,
        addressuser:[],
        panes:[],
        operation:[],
        setState:0,//设置参数权限
        super:0,//导出excel权限
        selUser_dev:'',//到期提醒用户
        resolution:{ width: 0, height: 0 },
        runningtime:0,
        pwdChange:{},
        mainTodo:'',
    })
    return (
        <StateContext.Provider value={{ state, dispatch }}>
            {props.children}
        </StateContext.Provider>
    )
}