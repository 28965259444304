import React, { createContext, useContext, useEffect, useState, useReducer } from 'react'
import { Table, Button, Input, Select, Modal, Tag, TreeSelect, Space, message, TimePicker, Dropdown, Menu, Tabs, Tooltip} from 'antd'
import { StateContext } from '../../../state';
import http from '../../../../../utils/server'
import SysTs from '../../../../../../public/assets/systs.png'
import {ExclamationCircleOutlined, SearchOutlined, DownOutlined} from '@ant-design/icons';
import { Mode } from '../../../../../App';
import Draggable from 'react-draggable';
import './index.less'
const { TabPane } = Tabs;

function findUnderUser(nop, list, underUser) {
    if (list) {
        const nops = list.filter(x => { return x.parent === nop })
        if (nops.length === 0) {
            return
        } else {
            for (var j = 0; j < nops.length; j++) {
                underUser.push(nops[j]);
                findUnderUser(nops[j].id, list,underUser);
            }
        }
    }
}

function checkNumber(theObj) {
    var reg = /^\d*$/;
    if (reg.test(theObj)) {
      return true;
    }
    return false;
}
const AlarmUserContext = createContext();
let qrtime = 0, qrInterval = "", flowid = 0
function AlarmUserNew() {
    const { actAlarmUser, dispatchAlarmUser } = useContext(AlarmUserContext)
    const { state } = useContext(StateContext)

    const [qrcode, setQrcode] = useState("")
    const [qrcodeUrl, setQrcodeUrl] = useState("")
    const [wechat, setWechat] = useState("")
    const [defExpend,setDefExpend]=useState([]);
    const [smsCount,setSmsCount] = useState(0);//可分配短信条数
    const [userDis,setUserDis]=useState([]);//普通用户分配的区域
    const [selDis,setSelDis]=useState([]);//可选择的区域
    function fetchDataQR() {
        http.get("/v1/cfmqrcode?flowid=" + flowid, {}).then(data => {
            if (data.data.length > 0) {
                setWechat(data.data)
                setQrcode("http://wx.liuliyong5.xyz/static/assets/qrok.png")
                clearInterval(qrInterval)
            }
        })
    }

    useEffect(() => {
        dispatchAlarmUser({ type: 'update', AlarmUser: { ...actAlarmUser, wechat: wechat } })
    }, [wechat])
    useEffect(() => {
        var userLogin = state.main;
        var list = [];
        state.systemuser.list.filter(x=>x.parent===userLogin.id).map(m=>list.push(m.id+""));
        setDefExpend(list);
        let temp=[];
        if(Mode==='soc'){
                
            if(actAlarmUser.district!==null){
                actAlarmUser.district.length===0?
                state.district.filter(x => { return x.user === userLogin.id }).map(item => 
                    temp.push({label:item.name,value:item.id})
                ):state.district.filter(x => x.user ===actAlarmUser.user).map(item => 
                    temp.push({label:item.name,value:item.id}))
            }
        }else{
            state.district.map(item => 
                temp.push({label:item.name,value:item.id})
            )
        }
        setSelDis(temp);
        setWechat(actAlarmUser.wechat);
        flowid = (new Date()).valueOf();
        // setQrflowid(flowid)
        http.get("/v1/getqrcode?flowid=" + flowid, {}).then(data => {
            if (data.code === 0) {
                setQrcodeUrl("https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=" + data.data.ticket);
                setQrcode("https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=" + data.data.ticket)
                qrtime = 0
                qrInterval = setInterval(() => {
                    qrtime++
                    if (qrtime > 60) {
                        setQrcode("http://wx.liuliyong5.xyz/static/assets/qrtimeout.png")
                        clearInterval(qrInterval)
                    }
                    fetchDataQR()
                }, 2000)
            }
        })
        http.get("/v1/userdistricts", {}).then(data => {
            if (data.code === 0) {
                setUserDis(data.data);
            }
        })
        return () => {
            clearInterval(qrInterval)
        }
    }, [])
    return (
        <div >
            {
                // (state.main.type === 101 || state.main.type === 102) &&
                Mode==='soc'&&
                <div style={{ display: 'flex' }}>
                    <div style={{ padding: '0 11px', paddingTop: 4, backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRight: '0px', borderRadius: 2 }}><span style={{color:'red'}}>*&nbsp;</span>关联用户：</div>
                    <TreeSelect
                        showSearch
                        treeNodeFilterProp='name'
                        treeData={state.systemuser.tree}
                        dropdownStyle={{ maxHeight: 400, overflow: 'scroll' }}
                        style={{ flex: 1 }}
                        // treeDefaultExpandAll
                        treeDefaultExpandedKeys={defExpend}
                        optionFilterProp="children"
                        value={actAlarmUser.user}
                        onChange={(value) => {
                            const us = state.systemuser.list.filter(x => { return x.id === value })
                            if (us.length > 0) {
                                const u = us[0]
                                setSmsCount(u.smscount-u.smssendcount);
                                dispatchAlarmUser({ type: 'update', AlarmUser: { ...actAlarmUser, user: u.id} })
                                let temp=[]
                                if(us[0].type===104){
                                    userDis.filter(x => { return x.user === u.id }).map(item => {
                                        let disName=state.district.filter(y=>y.id===item.district)[0].name;
                                        temp.push({label:disName,value:item.district})
                                    }   
                                    )
                                    setSelDis(temp);
                                }else{

                                    state.district.filter(x => { return x.user === u.id }).map(item => 
                                        temp.push({label:item.name,value:item.id})
                                    )
                                    setSelDis(temp);
                                }
                            }
                        }}
                    />
                </div>
            }
            
            <div style={{ display: 'flex',marginTop:4 }}>
                <div style={{ padding: '0 11px', paddingTop: 4, backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRight: '0px', borderRadius: 2 }}>
                    {
                       <span style={{color:'red'}}>*&nbsp;</span>
                    }
                    绑定区域：
                </div>
                <Select
                    mode="multiple"
                    style={{ width: '100%', flex: 1 }}
                    placeholder="选择绑定区域"
                    // defaultValue={disNames}
                    defaultValue={actAlarmUser.district!==null&&actAlarmUser.district}
                    onChange={value => {
                        value.length===0?dispatchAlarmUser({ type: 'update', AlarmUser: { ...actAlarmUser, district: value ,alarmtypesms:[711],alarmtypewe:[611],alarmtypephone:[811]} }):
                        dispatchAlarmUser({ type: 'update', AlarmUser: { ...actAlarmUser, district: value ,alarmtypesms:[701,706,707,711],alarmtypewe:[601,606,607,611],alarmtypephone:[801,806,807,811]} })
                    }}
                    maxTagCount={'responsive'}
                    options={selDis}
                >
                    
                </Select>
            </div>
            <Input style={{ marginBottom: 5 }} addonBefore={<span style={{color:'red'}}>*&nbsp;<span style={{color:'#000'}}>联系人姓名：</span></span>} value={actAlarmUser.name}
                onChange={(e) => { dispatchAlarmUser({ type: 'update', AlarmUser: { ...actAlarmUser, name: (e.target.value) } }) }} />

            <Tabs defaultActiveKey="1">
                <TabPane tab="短信" key="1">
                    <Input style={{ marginBottom: 10,marginTop:50 }} addonBefore={<span style={{color:'red'}}>*&nbsp;<span style={{color:'#000'}}>短信手机号码：</span></span>} value={actAlarmUser.phone}
                    onChange={(e) => { dispatchAlarmUser({ type: 'update', AlarmUser: { ...actAlarmUser, phone: Number(e.target.value) } }) }} />
                    <br />
                    <div style={{ display: 'flex',marginTop:10,  marginBottom: 10,}}>
                        <div style={{ padding: '0 11px', paddingTop: 4, backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRight: '0px', borderRadius: 2 }}>
                            <span style={{color:'red'}}>*&nbsp;</span>
                            短信报警类型：
                        </div>
                        <Select
                            mode="multiple"
                            style={{ width: '100%', flex: 1 }}
                            placeholder="选择报警类型"
                            value={actAlarmUser.alarmtypesms}
                            onChange={value => {
                                dispatchAlarmUser({ type: 'update', AlarmUser: { ...actAlarmUser, alarmtypesms: value } })
                            }}
                            maxTagCount={'responsive'}
                        >
                            {
                                (actAlarmUser.district===null||actAlarmUser.district.length===0)?state.alarmtype.filter(x => { return x.code === 711 }).map(item => (
                                    <Select.Option key={item.code} value={item.code}>{item.value}</Select.Option>
                                )):
                                state.alarmtype.filter(x => { return x.code > 700&&x.code<800 }).map(item => (
                                    <Select.Option key={item.code} value={item.code}>{item.value}</Select.Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div style={{display:'none',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
                        <Input style={{ marginTop: 5 }} addonBefore="可分配报警短信条数：" value={smsCount} disabled={true}
                        />
                        <Input style={{ marginTop: 5 }} addonBefore="报警短信条数：" value={actAlarmUser.smscount}
                            onChange={(e) => { dispatchAlarmUser({ type: 'update', AlarmUser: { ...actAlarmUser, smscount: Number(e.target.value) } }) }} />
                    </div>
                    <div>
                        <Input style={{ marginTop: 10,marginBottom:10 }} addonBefore="报警时段:" value={actAlarmUser.timerange} 
                        placeholder='请单击右下时间选择，默认全天'
                            onChange={val=>{
                                dispatchAlarmUser({ type: 'update', AlarmUser: { ...actAlarmUser, timerange:val.target.value } })
                            }}
                        ></Input>
                    </div>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <Input style={{ marginTop: 10,marginBottom:10,width:160}} addonBefore="延时:" value={actAlarmUser.timerelay} addonAfter='分'
                            onChange={val=>{
                                let temp = val.target.value;
                                if(checkNumber(temp)){
                                    dispatchAlarmUser({ type: 'update', AlarmUser: { ...actAlarmUser, timerelay: temp*1} })
                                }
                            }}
                        ></Input>
                        <TimePicker.RangePicker format='HH:mm' onChange={(dates,dateStr)=>{
                            if(dateStr[0]!==''){
                                let temp = actAlarmUser.timerange===undefined?'':actAlarmUser.timerange;
                                temp+=`${dateStr[0]}-${dateStr[1]};`;
                                dispatchAlarmUser({ type: 'update', AlarmUser: { ...actAlarmUser, timerange:temp } })
                            }
                        }}/>
                    </div>
                    <br />
                    <br />
                </TabPane>
                <TabPane tab="微信" key="2">
                    
                    <div style={{ height: 150, display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <div style={{textAlign:'center'}}>
                                <span style={{color:'red'}}>微信扫码进行绑定</span>
                            </div>
                            <img alt={"q"} src={qrcode} width={150} height={150} onClick={()=>{
                                if(wechat===''){
                                    if(qrtime>60){
                                        qrtime=0;
                                        setQrcode(qrcodeUrl);
                                        qrInterval = setInterval(() => {
                                            qrtime++
                                            if (qrtime > 60) {
                                                setQrcode("http://wx.liuliyong5.xyz/static/assets/qrtimeout.png")
                                                clearInterval(qrInterval)
                                            }
                                            fetchDataQR()
                                        }, 2000)
                                    }else{
                                        qrtime=0;
                                    }
                                    
                                }
                            }} />
                        </div>
                        
                        <img alt={"q"} src={SysTs} height={150} />
                    </div>
                    <br />
                    <div style={{ display: 'flex' }}>
                        <div style={{ padding: '0 11px', paddingTop: 4, backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRight: '0px', borderRadius: 2 }}><span style={{color:'red'}}>*&nbsp;</span>微信报警类型：</div>
                        <Select
                            mode="multiple"
                            style={{ width: '100%', flex: 1 }}
                            placeholder="选择报警类型"
                            value={actAlarmUser.alarmtypewe}
                            onChange={value => {
                                dispatchAlarmUser({ type: 'update', AlarmUser: { ...actAlarmUser, alarmtypewe: value } })
                            }}
                            maxTagCount={'responsive'}
                        >
                            {
                                (actAlarmUser.district===null||actAlarmUser.district.length===0)?state.alarmtype.filter(x => { return x.code === 611 }).map(item => (
                                    <Select.Option key={item.code} value={item.code}>{item.value}</Select.Option>
                                )):
                                state.alarmtype.filter(x => { return x.code < 700 }).map(item => (
                                    <Select.Option key={item.code} value={item.code}>{item.value}</Select.Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <Input style={{ marginTop: 5,marginBottom:5 }} addonBefore="报警时段:" value={actAlarmUser.timerangewechat} 
                        placeholder='请单击右下时间选择，默认全天'
                            onChange={val=>{
                                dispatchAlarmUser({ type: 'update', AlarmUser: { ...actAlarmUser, timerangewechat:val.target.value } })
                            }}
                        ></Input>
                    </div>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <Input style={{ marginTop: 5,marginBottom:5,width:160}} addonBefore="延时:" value={actAlarmUser.timerelaywechat} addonAfter='分'
                            onChange={val=>{
                                let temp = val.target.value;
                                if(checkNumber(temp)){
                                    dispatchAlarmUser({ type: 'update', AlarmUser: { ...actAlarmUser, timerelaywechat: temp*1} })
                                }
                            }}
                        ></Input>
                        <TimePicker.RangePicker format='HH:mm' onChange={(dates,dateStr)=>{
                            if(dateStr[0]!==''){
                                let temp = actAlarmUser.timerangewechat===undefined?'':actAlarmUser.timerangewechat;
                                temp+=`${dateStr[0]}-${dateStr[1]};`;
                                dispatchAlarmUser({ type: 'update', AlarmUser: { ...actAlarmUser, timerangewechat:temp } })
                            }
                        }}/>
                    </div>
                </TabPane>
                <TabPane tab="语音" key="3">
                    <Input style={{ marginBottom: 10,marginTop:50 }} addonBefore={<span style={{color:'red'}}>*&nbsp;<span style={{color:'#000'}}>语音手机号码：</span></span>} value={actAlarmUser.vphone}
                    onChange={(e) => { dispatchAlarmUser({ type: 'update', AlarmUser: { ...actAlarmUser, vphone: Number(e.target.value) } }) }} />
                    <br />
                    <div style={{ display: 'flex',marginTop:10,  marginBottom: 10,}}>
                        <div style={{ padding: '0 11px', paddingTop: 4, backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRight: '0px', borderRadius: 2 }}>
                            <span style={{color:'red'}}>*&nbsp;</span>
                            语音报警类型：
                        </div>
                        <Select
                            mode="multiple"
                            style={{ width: '100%', flex: 1 }}
                            placeholder="选择报警类型"
                            value={actAlarmUser.alarmtypephone}
                            onChange={value => {
                                dispatchAlarmUser({ type: 'update', AlarmUser: { ...actAlarmUser, alarmtypephone: value } })
                            }}
                            maxTagCount={'responsive'}
                        >
                            {
                                (actAlarmUser.district===null||actAlarmUser.district.length===0)?state.alarmtype.filter(x => { return x.code === 811 }).map(item => (
                                    <Select.Option key={item.code} value={item.code}>{item.value}</Select.Option>
                                )):
                                state.alarmtype.filter(x => { return x.code > 800 }).map(item => (
                                    <Select.Option key={item.code} value={item.code}>{item.value}</Select.Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <Input style={{ marginTop: 10,marginBottom:10 }} addonBefore="报警时段:" value={actAlarmUser.timerangephone} 
                        placeholder='请单击右下时间选择，默认全天'
                            onChange={val=>{
                                dispatchAlarmUser({ type: 'update', AlarmUser: { ...actAlarmUser, timerangephone:val.target.value } })
                            }}
                        ></Input>
                    </div>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <Input style={{ marginTop: 10,marginBottom:10,width:160}} addonBefore="延时:" value={actAlarmUser.timerelayphone} addonAfter='分'
                            onChange={val=>{
                                let temp = val.target.value;
                                if(checkNumber(temp)){
                                    dispatchAlarmUser({ type: 'update', AlarmUser: { ...actAlarmUser, timerelayphone: temp*1} })
                                }
                            }}
                        ></Input>
                        <TimePicker.RangePicker format='HH:mm' onChange={(dates,dateStr)=>{
                            if(dateStr[0]!==''){
                                let temp = actAlarmUser.timerangephone===undefined?'':actAlarmUser.timerangephone;
                                temp+=`${dateStr[0]}-${dateStr[1]};`;
                                dispatchAlarmUser({ type: 'update', AlarmUser: { ...actAlarmUser, timerangephone:temp } })
                            }
                        }}/>
                    </div>
                    <br />
                    <br />
                </TabPane>
            </Tabs>
        </div >
    )
}

function AlarmUserManage() {
    const { state, dispatch } = useContext(StateContext)
    const [actAlarmUser, dispatchAlarmUser] = useReducer((state, action) => {
        if (action.type === 'update') {
            return action.AlarmUser
        } else if (action.type === 'clear') {
            return {
                name: '',
                user: action.user,
                wechat: '',
                district: [],
                alarmtypewe: [611],
                alarmtypesms: [711],
                alarmtypephone: [811],
                wechatcount:200,
            }
        }
        return state
    }, []);
    const [showNewModel, setShowNewModel] = useState(false)
    const [showFixModel, setShowFixModel] = useState(false)
    const [selUser,setSelUser] = useState(0);//筛选用户
    const [alarmUser,setAlarmUser]=useState([]);
    const [defExpend,setDefExpend]=useState([]);
    const [modalBounds,setModalBounds] = useState({left: 0, top: 0, bottom: 0, right: 0 });//对话框移动坐标
    const [modalMoveDisabled, setModalMoveDisabled] = useState(true);//对话框拖动功能关闭
    const draggleRef = React.createRef();//移动对话框
    const [tableLoad,setTableLoad] =  useState(true);
    let tempUsers=[];//选中用户下的所有用户
    const [innerWidth,setInnerWidth]= useState(0);
    useEffect(() => {
        setTableLoad(true)
        http.get("/v1/alarmsuer", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'alarmsuer', alarmsuer: data.data })
                setTableLoad(false)
                setAlarmUser(data.data);
                
            }
        })
        http.get("/v1/alarmtype", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'alarmtype', alarmtype: data.data.sort((a,b)=>a.id-b.id) })
            }
        })
        http.get("/v1/district", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'district', district: data.data })
            }
        })
        http.get("/v1/device", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'device', device: data.data })
            }
        })
        http.get("/v1/users", {}).then(data => {
            if (data.code === 0) {
                let temp = data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh'));
                dispatch({ type: 'systemuser', systemuser: temp })
                if(data.data.length>0){
                    var userLogin =data.data[0];
                    var list = [];
                    temp.filter(x=>x.parent===userLogin.id).map(m=>list.push(m.id+""));
                    setDefExpend(list);         
                }    
            }
        })
        setInnerWidth(window.innerWidth);
    }, [])
    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            width:70,
            align: 'center',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder='联系人姓名'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90}}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

            onFilter: (value, record) =>
                record['name']
                    ? (record['name'].toString().toLowerCase().includes(value.toLowerCase()))
                    : '',
        },
        {
            title: '电话',
            dataIndex: 'phone',
            align: 'center',
            width:120,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder='联系人姓名'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

            onFilter: (value, record) =>
                record['phone']
                    ? (record['phone'].toString().toLowerCase().includes(value.toLowerCase()))
                    : '',
        },
        {
            title: '关联用户',
            dataIndex: 'user',
            align: 'center',
            width:300,
            render: (text, record) => (
                <Tooltip title = {state.systemuser.list.filter(x => x.id === record.user).length > 0 ?
                    state.systemuser.list.filter(x => x.id === record.user)[0].name : ''}>
                <div>
                    {
                        state.systemuser.list.filter(x => x.id === record.user).length > 0 ?
                            state.systemuser.list.filter(x => x.id === record.user)[0].name : '无'
                    }
                </div>
                </Tooltip>
            ),
            // filters: state.systemuser.tree,
            // onFilter: (value, record) => record.user.includes(value),
        },
        {
            title: '绑定区域',
            width:80,
            dataIndex: 'district',
            align: 'center',
            render:(text, record) => {
                let temp =state.district.filter(x => { return record.district?.indexOf(x.id) > -1 });
                let len = temp.length;
                let n =3;
                let lineNub=len%n===0?len/n:Math.floor(len/n+1);
                let tempArrs=[];
                for(let i=0;i<lineNub;i++){
                    tempArrs.push(temp.slice(i*n,i*n+n))
                }
                return(
                <Dropdown overlay={
                    <Menu>
                        {
                            tempArrs.map((items,index)=>(
                                <Menu.Item key={index+''}>
                                    {
                                        items.map(item => 
                                            
                                            <Tag color="volcano" key={item.id}>{item.name}</Tag>
                                                
                                        )
                                    }
                                </Menu.Item> 
                            ))
                        }

                    </Menu>}
                >
                    <a>详情({temp.length})</a>
                </Dropdown>
                // <Button style={{borderRadius:10}} type='primary'
                // onClick={()=>{
                //     Modal.info({
                //         title: '已绑定区域',
                //         content: (
                //           <div>
                //             {
                //                 state.district.filter(x => { return record.district?.indexOf(x.id) > -1 }).map(item => (
                                    
                //                     <Tag color="volcano" key={item.id}>{item.name}</Tag>
                                   
                //                 ))
                //             }
                //           </div>
                //         ),
                //         onOk() {},
                //       });
                // }}>点击查看 ({state.district.filter(x => { return record.district?.indexOf(x.id) > -1 }).length})</Button>
            )}
        },
        // 微信
        {
            title: '微信报警',
            dataIndex: 'alarmtype',
            align: 'left',
            render: (text, record) => (
                <>
                {
                    record.wechat!==""?
                    <div>
                        <div style={{overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis',padding:0}}>
                            <Tooltip title={state.alarmtype.filter(x => { return record.alarmtypewe?.indexOf(x.code) > -1 }).map(item => (
                                    <Tag style={{borderRadius:8}} color="volcano" key={item.code}>{item.value}</Tag>
                                ))} color = {'#1296db'}>
                                <span style={{color:'#1296db'}}>类型：</span>
                            </Tooltip>
                            {
                                state.alarmtype.filter(x => { return record.alarmtypewe?.indexOf(x.code) > -1 }).map(item => (
                                    <Tag style={{borderRadius:8}} color="volcano" key={item.code}>{item.value}</Tag>
                                ))
                            }
                        </div>
                        <div style={{overflow:'hidden',whiteSpace:'nowrap',width:innerWidth<1600?175:260,textOverflow:'ellipsis',padding:0}}>
                            <span style={{color:'#1296db'}}>延时(分):</span><span>{record.timerelaywechat}</span>
                        </div>
                        <div style={{overflow:'hidden',whiteSpace:'nowrap',width:innerWidth<1600?175:260,textOverflow:'ellipsis',padding:0}}>
                            <span style={{color:'#1296db'}}>时间段:</span><span>{record.timerangewechat}</span>
                        </div>
                    </div>
                    :
                    <div>
                        未设置
                    </div>
                }
                </>
            )

        },
// 短信
        {
            title: '短信报警',
            dataIndex: 'alarmtype',
            align: 'left',
            render: (text, record) => (
                <>
                {
                    record.phone!==0?
                    <div>
                        <div style={{overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis',padding:0}}>
                            <Tooltip title={state.alarmtype.filter(x => { return record.alarmtypesms?.indexOf(x.code) > -1 }).map(item => (
                                    <Tag color="volcano" style={{borderRadius:8}} key={item.code}>{item.value}</Tag>
                                ))} color = {'#1296db'}>
                                <span style={{color:'#1296db'}}>类型：</span>
                            </Tooltip>
                            {
                                state.alarmtype.filter(x => { return record.alarmtypesms?.indexOf(x.code) > -1 }).map(item => (
                                    <Tag color="volcano" style={{borderRadius:8}} key={item.code}>{item.value}</Tag>
                                ))
                            }
                        </div>
                        <div><span style={{color:'#1296db'}}>延时(分):</span><span>{record.timerelay}</span></div>
                        <div><span style={{color:'#1296db'}}>时间段:</span><span>{record.timerange}</span></div>
                    </div>
                    :
                    <div>
                        未设置
                    </div>
                }
                </>
            )

        },
        {
            title: '语音报警',
            dataIndex: 'alarmtype',
            align: 'left',
            render: (text, record) => (
                <>
                {
                    record.vphone!==0?
                    <div>
                        <div style={{overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis',padding:0}}>
                            <Tooltip title={state.alarmtype.filter(x => { return record.alarmtypephone?.indexOf(x.code) > -1 }).map(item => (
                                <Tag color="volcano" style={{borderRadius:8}} key={item.code}>{item.value}</Tag>
                            ))} color = {'#1296db'}>
                                <span style={{color:'#1296db'}}>类型：</span>
                            </Tooltip>
                            {
                                state.alarmtype.filter(x => { return record.alarmtypephone?.indexOf(x.code) > -1 }).map(item => (
                                    <Tag color="volcano" style={{borderRadius:8}} key={item.code}>{item.value}</Tag>
                                ))
                            }
                        </div>
                        <div><span style={{color:'#1296db'}}>延时(分):</span><span>{record.timerelayphone}</span></div>
                        <div><span style={{color:'#1296db'}}>时间段:</span><span>{record.timerangephone}</span></div>
                    </div>
                    :
                    <div>
                        未设置
                    </div>
                }
                    
                </>
            )

        },
        // {
        //     title:'延时(分)',
        //     dataIndex:'timerelay',
        //     align:'center'
        // },
        // {
        //     title:'时间段',
        //     dataIndex:'timerange',
        //     align:'center'
        // },
        {
            title: '操作',
            dataIndex: 'operation',
            align: 'right',
            width:60,
            render: (text, record) => (
                state.main.type<104 &&
                <div>
                    <Dropdown
                        overlay={<Menu>
                            {/* 修改 */}
                            <Menu.Item key={record.id+"key"}>
                                <Button size='small' style={{borderRadius:10 }} type="primary" onClick={() => {
                                     setShowFixModel(true)
                                     //setActAlarmUser(record)
                                     dispatchAlarmUser({ type: 'update', AlarmUser: record })
                                }}>
                                    修改
                                </Button>
                            </Menu.Item>
                            {/* 删除 */}
                            {
                            <Menu.Item key={record.id+"keys"}>
                                <Button size='small' type='primary' danger style={{borderRadius:10 }} onClick={() => {
                                    Modal.confirm({
                                        centered: true,
                                        title: `确定要删除报警联系人${record.name}吗？`,
                                        icon: <ExclamationCircleOutlined />,
                                        content: Mode==='soc'?`用户<${state.systemuser.list.filter(x => x.id === record.user)[0].name}>下的${record.name}删除之后将不可恢复！！`:`${record.name}删除之后将不可恢复！！`,
                                        okText: '删除',
                                        okType: 'danger',
                                        cancelText: '取消',
                                        onOk() {
                                            http.delete("/v1/alarmsuer/" + record.id, { data: record }).then((data) => {
                                                http.get("/v1/alarmsuer", {}).then(data => {
                                                    if (data.code === 0) {
                                                        dispatch({ type: 'alarmsuer', alarmsuer: data.data });
                                                        if(selUser===0){//清空用户选择
                                                            setAlarmUser(data.data);
                                                        }else{
                                                            var temp=data.data.filter(x=>x.user===selUser);
                                                            tempUsers.map(item=>temp.push(...data.data.filter(x=>x.user===item.id)));
                                                            setAlarmUser(temp);
                                                        }  
                                                    }
                                                })
                                            })
                                        },
                                        onCancel() {
                                            console.log('Cancel');
                                        },
                                    });
                                }}>
                                    删除
                                </Button>
                            </Menu.Item>
                            }   
                        </Menu>}>
                        <a >操作<DownOutlined /></a>
                    </Dropdown>
                </div>
            ),
        },
    ]
    const onStart = (event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) return;
        setModalBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    return (
        <div style={{ padding: 15,flex:1 , position: 'relative',backgroundColor:'#fff'}}>
            <div style={{display:'flex',justifyContent:'space-between',marginBottom:10,border:'1px solid #ccc',borderRadius:5,padding:10}}>
                <div>
                {
                    Mode==='soc'&&<TreeSelect
                    showSearch
                    style={{ width: 400 }}
                    dropdownStyle={{ maxHeight: 500, overflow: 'auto' }}
                    placeholder="选择用户"
                    allowClear
                    // multiple
                    optionFilterProp="label"
                    treeDefaultExpandedKeys={defExpend}
                    onChange={value=> {
                        if(value===0||value===undefined){//清空用户选择
                            setAlarmUser(state.alarmsuer);
                            setSelUser(0)
                        }else{
                            setSelUser(value);
                            findUnderUser(value,state.systemuser.list,tempUsers);
                            var temp=state.alarmsuer?state.alarmsuer.filter(x=>x.user===value):[];
                            tempUsers.map(item=>temp.push(...state.alarmsuer.filter(x=>x.user===item.id)));
                            setAlarmUser(temp);
                        }  
                        
                    }}
                    treeData={state.systemuser.treeM}
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                />
                }
                </div>
                <div>
                    {
                        (state.main.type<104||state.main.type===105)&&
                        <Button style={{ backgroundColor: 'green', color: '#fff', borderRadius: 10 }}
                        onClick={() => {
                            setShowNewModel(true)
                            dispatchAlarmUser({ type: 'clear', user: state.main.id })
                        }}>新建报警联系人</Button>
                    }
                </div>
            </div>
            
            <div style={{height:'100%',overflowY:'scroll',width:'100%',position: 'absolute'}}>
                <Table bordered pagination={{ showSizeChanger:true,hideOnSinglePage:true }} rowKey={record => record.id} columns={Mode==='soc'?columns:state.main.type===104?columns.filter(x=>x.title!=='关联用户'&&x.title!='操作'):columns.filter(x=>x.title!=='关联用户')} size='small' dataSource={alarmUser} 
                    rowClassName={record=>record.wechat===''&&'unWechat'}  loading={tableLoad}
                    scroll={{y:state.resolution.height<800?420:state.resolution.height<1000?550:700,x:'100%'}}
                />
            </div>
            
            {/* 新建报警联系人 */}
            <Modal maskClosable={false} title={
                <div
                style={{
                    width: '100%',
                    cursor: 'move',
                }}
                onMouseOver={() => {
                    setModalMoveDisabled(false);
                }}
                onMouseOut={() => {
                    setModalMoveDisabled(true);
                }}
                >
                新建报警联系人
                </div>
                } visible={showNewModel}
                modalRender={modal => (
                    <Draggable
                      disabled={modalMoveDisabled}
                      bounds={modalBounds}
                      onStart={onStart}
                    >
                      <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    if(state.systemuser.list.filter(x=>x.id===actAlarmUser.user)[0].type===104){
                        if(actAlarmUser.district.length===0){
                            return message.error('绑定区域不能为空');
                        }
                    }
                    if(actAlarmUser.name===''){
                        return message.error('名称不能为空');
                    }
                    if(actAlarmUser.wechat===''&&(actAlarmUser.phone===0||actAlarmUser.phone==='')){
                        return message.error('微信或电话不能同时为空');
                    }
                    if(actAlarmUser.wechat===''){
                        Modal.confirm({
                            title: '微信未绑定成功，是否保存？？',
                            icon: <ExclamationCircleOutlined />,
                            content: '',
                            okText: '确认',
                            cancelText: '取消',
                            onCancel(){
                                return
                            },
                            onOk(){
                                http.post("/v1/alarmsuer", actAlarmUser).then((data) => {
                                    http.get("/v1/alarmsuer", {}).then(data => {
                                        if (data.code === 0) {
                                            dispatch({ type: 'alarmsuer', alarmsuer: data.data });
                                            if(selUser===0){//清空用户选择
                                                setAlarmUser(data.data);
                                            }else{
                                                var temp=data.data.filter(x=>x.user===selUser);
                                                tempUsers.map(item=>temp.push(...data.data.filter(x=>x.user===item.id)));
                                                setAlarmUser(temp);
                                            }  
                                        }
                                    })
                                })
                                setTimeout(() => {
                                    setShowNewModel(false)
                                    dispatchAlarmUser({ type: 'clear', user: state.main.id })
                                    
                                }, 1000);
                                
                            }
                            
                          });
                    }else{
                        http.post("/v1/alarmsuer", actAlarmUser).then((data) => {
                            http.get("/v1/alarmsuer", {}).then(data => {
                                if (data.code === 0) {
                                    dispatch({ type: 'alarmsuer', alarmsuer: data.data });
                                    if(selUser===0){//清空用户选择
                                        setAlarmUser(data.data);
                                    }else{
                                        var temp=data.data.filter(x=>x.user===selUser);
                                        tempUsers.map(item=>temp.push(...data.data.filter(x=>x.user===item.id)));
                                        setAlarmUser(temp);
                                    }  
                                }
                            })
                        })
                        setShowNewModel(false)
                        dispatchAlarmUser({ type: 'clear', user: state.main.id })
                    }
                    
                }}
                onCancel={() => {
                    setShowNewModel(false)
                    dispatchAlarmUser({ type: 'clear', user: state.main.id })
                }}>
                <AlarmUserContext.Provider value={{ actAlarmUser, dispatchAlarmUser }}>
                    <AlarmUserNew />
                </AlarmUserContext.Provider>
            </Modal>
            <Modal maskClosable={false} title="修改报警联系人" visible={showFixModel}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    if(actAlarmUser.name===''){
                        return message.error('名称不能为空');
                    }
                    http.put("/v1/alarmsuer/" + actAlarmUser.id, actAlarmUser).then((data) => {
                        http.get("/v1/alarmsuer", {}).then(data => {
                            if (data.code === 0) {
                                dispatch({ type: 'alarmsuer', alarmsuer: data.data });
                                if(selUser===0){//清空用户选择
                                    setAlarmUser(data.data);
                                }else{
                                    var temp=data.data.filter(x=>x.user===selUser);
                                    tempUsers.map(item=>temp.push(...data.data.filter(x=>x.user===item.id)));
                                    setAlarmUser(temp);
                                }  
                            }
                        })
                    })
                    setShowFixModel(false)
                    dispatchAlarmUser({ type: 'clear', user: state.main.id })
                }}
                onCancel={() => {
                    setShowFixModel(false)
                    dispatchAlarmUser({ type: 'clear', user: state.main.id })
                }}>
                <AlarmUserContext.Provider value={{ actAlarmUser, dispatchAlarmUser }}>
                    <AlarmUserNew />
                </AlarmUserContext.Provider>
            </Modal>
        </div>
    )
}

export default AlarmUserManage